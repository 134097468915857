import React from 'react';
import '../scss/_footer.scss';

const logoRe = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/logo.png';
const logoAu =
  'https://raw.githubusercontent.com/triciopa/triciopa/main/logos/others/auth0.svg';
const logoSa =
  'https://raw.githubusercontent.com/triciopa/triciopa/main/logos/others/sass-1.svg';
const logoNo =
  'https://raw.githubusercontent.com/triciopa/triciopa/main/logos/frameworks/nodejs.svg';
const logoEx =
  'https://raw.githubusercontent.com/triciopa/triciopa/main/logos/frameworks/expressjs.svg';

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <p>Made with:</p>
    <img className="mb-3 app-logo" src={logoRe} alt="React logo" width="32" />
    <img className="mb-3 app-logo" src={logoAu} alt="Auth0" width="24" />
    <img className="mb-3 app-logo" src={logoSa} alt="Sass" width="32" />
    <img className="mb-3 app-logo" src={logoNo} alt="Node" width="32" />
    <img className="mb-3 app-logo" src={logoEx} alt="Express" width="32" />
    <p>©2021</p>
  </footer>
);

export default Footer;
