import React from 'react';
import '../scss/_about-me.scss';

const AboutMe = () => {
  const profileImg =
    'https://media-exp1.licdn.com/dms/image/C4E03AQEexcZGXE2TAA/profile-displayphoto-shrink_800_800/0/1605120667310?e=1626912000&v=beta&t=fKAWqod75D-ASgqZkzGFskH9oziNzTzhnkYs8emS7zY';

  return (
    <div>
      <div className="row align-items-center profile-header">
        <div className="col-md-2 mb-3">
          <img
            src={profileImg}
            alt="Patricio"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </div>
        <div className="col-md text-center text-md-left">
          <h2>Patricio</h2>
          <p className="lead text-muted">Living in Buenos Aires, Argentina</p>
        </div>
        <a
          href={`https://www.linkedin.com/in/patricio-alejandro-gatti`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="LinkedIn"
            src="https://raw.githubusercontent.com/triciopa/triciopa/main/logos/others/linkedin-icon-2.svg"
            width="60"
            height="60"
          />
        </a>
        <a
          href={`https://github.com/triciopa`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="GitHub"
            src="https://raw.githubusercontent.com/triciopa/triciopa/main/logos/cloud/github.svg"
            width="72"
            height="72"
          />
        </a>
      </div>
      <div className="content-text">
        <h4>Recruiters be ready</h4>
        <p>
          If you want to hire me, take a look up and click to my LinkedIn
          profile or visit my repositories in GitHub. The most remarkable two
          projects that I like to show to you, are these:
        </p>
        <ul>
          <li>
            <h5>
              <a href="https://github.com/triciopa/recipes-app">
                Recipes app...
              </a>
            </h5>
            <p>
              {' '}
              ...is SPA app that I developed by myself, which browses for
              recipes and filters by diet, among other features. I release a{' '}
              <a
                href="https://www.youtube.com/watch?v=zNpAxib4T9Y"
                target="_blank"
                rel="noreferrer"
              >
                video
              </a>{' '}
              showing how it was made and how it works.
            </p>
          </li>
          <li>
            <h5>
              <a href="https://github.com/triciopa/e-commerce-Agroplace">
                Agroplace...
              </a>{' '}
            </h5>
            <p>
              ...is an e-commerce web application which has been a result from a
              hard teamwork effort. Includes numerous features, also you could
              see the video presentation{' '}
              <a
                href="https://www.youtube.com/watch?v=cXkNWNEK1So"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </li>
        </ul>
        <div className="content-more">
          <h4>Where I've been and where I go</h4>
          <p>
            I am skilled in software development technologies and I have
            experience in web application projects based onReact.js/Redux.js on
            the front-end, Node.js/Express.js in back-end, and
            PostgreSQL/Sequelize for databases.
          </p>
          <p>
            {' '}
            Other technologies that I've applied: Passport.js (authentication),
            Digital Ocean + NGINX (deployment), SASS and Wordpress. Also I'm
            learning Python, GraphQL, Java y Typescript.
          </p>
          <p>
            {' '}
            I desire to combine my background as a work team leader with the
            world of programming and software development, backed by agile
            methodologies such as Scrum and Kanban, so I am looking for jobs as
            a software/web developer.
          </p>
          <p>
            {' '}
            I consider it important to have a balance between interpersonal and
            technical skills, to meet the objectives of any venture or project,
            so I hope to bring these values to my next collaboration.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
