import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import '../scss/_external-api.scss';

const ExternalApi = () => {
  const [message, setMessage] = useState('');
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const { getAccessTokenSilently } = useAuth0();

  const callApi = async () => {
    try {
      const response = await axios.get(
        `${serverUrl}/api/messages/public-message`
      );

      // const responseData = await response.json();

      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const callSecureApi = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await axios.get(
        `${serverUrl}/api/messages/protected-message`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // const responseData = await response.json();

      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <div className="container">
      <h1>External API</h1>
      <p>
        <ul>
          <li>You can call our external API that is running in Heroku.</li>
          <li>
            Public message will request the back-end service and come back
            directly.
          </li>
          <li>
            The protected API call has an access token in its authorization
            header so it will check the access JWT against our Authorization
            Server in Auth0, befour emitting a response.
          </li>
          <li>
            To learn how to handle more functions with Authorization Server and
            retrieve data from associated services, press "Manage Auth0's API"
          </li>
        </ul>
      </p>
      <div
        className="btn-group mt-5"
        role="group"
        aria-label="External API Requests Examples"
      >
        <button type="button" className="btn btn-primary" onClick={callApi}>
          Get Public Message
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={callSecureApi}
        >
          Get Protected Message
        </button>

        <button type="button" className="btn btn-primary">
          <a
            href="https://auth0.com/docs/api/management/v2#!/Users/get_users"
            target="_blank"
            rel="noreferrer"
          >
            Manage Auth0's API
          </a>
        </button>
      </div>
      <p>
        <b>
          WARNING: The first time a message is requested, it might have a delay
          of 10s or more.
        </b>
      </p>
      {message && (
        <div className="mt-5">
          <h6 className="muted">Result</h6>
          <div className="container-fluid">
            <div className="row">
              <code className="col-12 text-light bg-dark p-4">{message}</code>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExternalApi;
