import React from 'react';
import '../scss/_home_content.scss';

const HomeContent = () => {
  return (
    <div className="next-steps">
      <h2 className="my-5 text-center">Login features</h2>
      <h5 className="subtitle text-center">
        Based in a project provided by{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://auth0.com/docs/quickstart/spa/react"
        >
          Auth0
        </a>
      </h5>

      <div className="row">
        <div className="col-md-5 mb-4">
          <h6 className="mb-3">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://auth0.com/docs/connections"
            >
              <i className="fas fa-link mr-2" />
              Social login available
            </a>
          </h6>
          <p>
            This web application is connected with <b>GitHub</b> and{' '}
            <b>LinkedIn</b>, so you can login with your username and password in
            these networks. Don't be afraid to have your identity stolen,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://auth0.com"
            >
              Auth0
            </a>
            's single sign on login protects your private data and credentials.
          </p>
        </div>

        <div className="col-md" />

        <div className="col-md-5 mb-4">
          <h6 className="mb-3">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://auth0.com/docs/multifactor-authentication"
            >
              <i className="fas fa-link mr-2" />
              Multi-Factor Authentication enabled
            </a>
          </h6>
          <p>
            There is an extra layer of security with Multi-factor
            Authentication, so as it's required to provide a <b>code by SMS</b>,{' '}
            <b>e-mail confirmation</b>, or use an <b>authenticator app</b> (e.g.
            Google Authenticator) if your credentials might be compromised.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-5 mb-4">
          <h6 className="mb-3">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://auth0.com/docs/anomaly-detection"
            >
              <i className="fas fa-link mr-2" />
              Anomaly Detection
            </a>
          </h6>
          <p>
            If a malicious attempt to access your application occurs, would have
            to get through four kinds of protections: <b>bot detection</b>{' '}
            (through <i> captcha</i>); stops <b>suspicious IP</b> addresses;{' '}
            <b>brute-force</b> detection against a single user; and general or{' '}
            <b>major public breaches</b> in passwords.
          </p>
        </div>

        <div className="col-md" />

        <div className="col-md-5 mb-4">
          <h6 className="mb-3">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://auth0.com/docs/rules"
            >
              <i className="fas fa-link mr-2" />
              Rules implemented
            </a>
          </h6>
          <p>
            Rules are JavaScript functions that execute when a user
            authenticates to this application. That's how your IP's{' '}
            <b>geographic information</b> is retrieved, you might be assigned a{' '}
            <b>role</b>, and also if you sign-up with your e-mail, you'll get a{' '}
            <b>cute photo</b> in your Profile section.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
