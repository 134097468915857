import React from 'react';
import { Link } from 'react-router-dom';
import '../scss/_hero.scss';

const Hero = () => {
  return (
    <div className="text-center hero">
      <h1 className="mb-4">My projects</h1>
      <p className="lead">
        These are my recent projects, which you can see also in{' '}
        <Link to="/about-me">my profile</Link>
      </p>
      <div className="hero-cards">
        <div className="hero-card" href>
          <a href="https://github.com/triciopa/e-commerce-Agroplace">
            <img src="https://raw.githubusercontent.com/triciopa/e-commerce-Agroplace/main/demo-img/tractor.png"></img>
            <h3>Agroplace</h3>
            <p>Click to view @GitHub</p>
          </a>
        </div>
        <div className="hero-card">
          <a href="https://github.com/triciopa/recipes-app">
            <img src="https://camo.githubusercontent.com/60393ca3669a0c0c94f823a49429d059d2ca7d2f677c71c2024e02ba1c3009c8/68747470733a2f2f73706f6f6e6163756c61722e636f6d2f6170706c69636174696f6e2f66726f6e74656e642f696d616765732f666f6f642d6170692f646f7567682e6a7067"></img>
            <h3>Food App</h3>
            <p>Click to view @GitHub</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
