import React, { useState } from 'react';
import '../scss/_profile.scss';

import {
  useAuth0,
  // withAuthenticationRequired
} from '@auth0/auth0-react';
// import { Loading } from '../components';

const Profile = () => {
  const { user } = useAuth0();
  const { name, picture, email, sub, nickname } = user;
  const country = user['https://example.com/country'];
  const city = user['https://example.com/city'];
  const [toggleData, setToggleData] = useState(false);

  return (
    <div>
      <div className="row align-items-center profile-header">
        <div className="col-md-2 mb-3">
          <img
            src={picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </div>
        <div className="col-md text-center text-md-left">
          <h2>{name}</h2>
          <p className="lead text-muted">
            {email + ' '}
            {sub.includes('auth0') ? (
              <a href={`https://auth0.com/`} target="_blank" rel="noreferrer">
                <img
                  alt="Auth0"
                  src="https://raw.githubusercontent.com/triciopa/triciopa/main/logos/others/auth0.svg"
                  width="24"
                  height="24"
                />
              </a>
            ) : sub.includes('linkedin') ? (
              <a
                href={`https://www.linkedin.com`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="LinkedIn"
                  src="https://raw.githubusercontent.com/triciopa/triciopa/main/logos/others/linkedin-icon-2.svg"
                  width="24"
                  height="24"
                />
              </a>
            ) : sub.includes('github') ? (
              <a
                href={`https://github.com/${nickname}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="GitHub"
                  src="https://raw.githubusercontent.com/triciopa/triciopa/main/logos/cloud/github.svg"
                  width="32"
                  height="32"
                />
              </a>
            ) : null}
          </p>
          <p className="lead text-muted">{city + ', ' + country}</p>
        </div>
        <button onClick={() => setToggleData(!toggleData)}>
          {!toggleData ? 'View JSON details' : 'Hide JSON details'}
        </button>
      </div>
      <div className="content-profile">
        {toggleData ? (
          <div className="row">
            <pre className="col-12 text-light bg-dark p-4">
              {JSON.stringify(user, null, 2)}
            </pre>
          </div>
        ) : null}
        <p>
          This is your dynamic profile info page, your data is authenticated in{' '}
          <b>
            {sub.includes('auth0')
              ? 'Auth0'
              : sub.includes('linkedin')
              ? 'LinkedIn'
              : sub.includes('github')
              ? 'GitHub'
              : null}
          </b>{' '}
          and then a user is created in our database (custom Auth0 DB). Here we
          can render elements that triggers interactions with Auth0's API
          endpoints and manage your user data. If you want to see the full
          object which contains all the info that we're accessing right now,
          click on the button "View JSON details"
        </p>
        <p>
          There's some functionalities developed here, from <b>rules</b> and{' '}
          <b>user information</b> provided by the hook "useAuth0":
          <ul>
            <li>
              <h5>Rule for default picture</h5>
              <p>
                If you sign up with an e-mail, a user will be created in Auth0's
                database and when you enter this section of the page, a{' '}
                <i>cute</i> default photo is going to render as your profile
                pic.
              </p>
            </li>
            <li>
              <h5>Rule for geolocation of IP</h5>
              <p>
                You might have noted that we have information about your
                location, or at least where your IP's is. A rule is triggered
                after you login and we receive that data inside the user's info.
              </p>
            </li>
            <li>
              <h5>Logo from the identity provider</h5>
              <p>
                Right to your e-mail you will find an icon from the provider
                that performed the authentication of your identity. Whether if
                you had logged in from GitHub or LinkedIn, Auth0 will receive
                authorization to use the identity to also authorize our
                application. So we're relying on Auth0 as our Identity Provider
                (or Authorization Server in OAuth 2.0 jargon).
              </p>{' '}
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Profile;

// export default withAuthenticationRequired(Profile, {
//   onRedirecting: () => <Loading />,
// });
