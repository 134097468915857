import React from 'react';
import loadingImg from '../img/827.svg';
// const loadingImg =
//   "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";
// const loadingImg =
//   'https://icons8.com/preloaders/preloaders/24/Blinking%20squares.gif';

const Loading = () => (
  <div className="spinner">
    <img src={loadingImg} alt="Loading..." />
  </div>
);

export default Loading;
